<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3" style="width:80%">
      <v-card class="radius-card hide_overflow pa-3">
        <v-toolbar class="pa-0" dense flat>
          Generate Voucher
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p class="small_txt grey--text mb-0">Kode</p>
                <v-text-field
                  dense
                  outlined
                  v-model="form.kode"
                  placeholder="Kuota"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <p class="small_txt grey--text mb-0">Pilih Paket</p>
                <v-select
                  :items="ListPackage"
                  v-model="form.layanan_id"
                  item-text="nama"
                  item-value="id"
                  dense
                  hide-details
                  placeholder="Klik untuk memilih paket"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6">
                <p class="small_txt grey--text mb-0">Kuota</p>
                <v-text-field
                  dense
                  outlined
                  type="number"
                  v-model="form.kuota"
                  placeholder="Kuota"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="small_txt grey--text mb-0">Deskripsi</p>
                <v-textarea
                  dense
                  outlined
                  v-model="form.deskripsi"
                  rows="3"
                  placeholder="Deskripsi Voucher"
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <p class="small_txt grey--text mb-0">Tanggal Expired</p>
                <v-menu
                  v-model="date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.expire_date"
                      readonly
                      placeholder="Klik untuk memilih Tanggal"
                      dense
                      hide-details
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.expire_date"
                    @input="date = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <p class="small_txt grey--text mb-0">Jam Expired</p>
                <v-menu
                  ref="menu"
                  v-model="time"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.expire_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.expire_time"
                      placeholder="Klik untuk memilih jam"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="time"
                    v-model="form.expire_time"
                    full-width
                    @click:minute="$refs.menu.save(form.expire_time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <p class="small_txt grey--text mb-0">Berlaku Untuk</p>
                <v-select
                  :items="listBerlaku"
                  v-model="berlaku"
                  item-text="txt"
                  item-value="val"
                  dense
                  hide-details
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" v-if="berlaku == 2">
                <p class="small_txt grey--text mb-0">Cari nama psikolog</p>
                <div class="d-flex align-center">
                  <v-text-field
                    dense
                    outlined
                    v-model="find"
                    @keyup="findPsy()"
                    placeholder="Ketik Nama Psikolog Disini..."
                    hide-details
                    class="mr-2"
                  ></v-text-field>
                  <div style="width:25px">
                    <v-btn icon small v-if="find.length" @click="clearSearch()"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </div>
                </div>
                <b class="purple--text" v-if="loadPsy">Loading...</b>
                <div v-if="psy.length && find.length">
                  <v-card
                    class="pa-3 rounded-lg my-2"
                    v-for="(item, i) in psy"
                    :key="i"
                    @click="pickPsy(item.psycholog)"
                  >
                    <div v-if="item.psycholog" class="d-flex align-center">
                      <div class="pp">
                        <img
                          :src="
                            item.psycholog.photo_profile
                              ? `${env}/upload/photo_profile/${item.psycholog.id}/${item.psycholog.photo_profile}`
                              : dummy
                          "
                          class="props_img"
                          alt=""
                        />
                      </div>
                      <div class="info_">
                        <b>{{ item.psycholog.nama_lengkap }}</b>
                        <p class="ma-0 color_txt">
                          {{
                            item.psycholog.bidang_id == 1
                              ? "Psikologi Klinis"
                              : item.psycholog.bidang_id == 2
                              ? "Psikologi Industri & Organisasi"
                              : "Psikologi Pendidikan"
                          }}
                        </p>
                      </div>
                    </div>
                  </v-card>
                </div>
                <div v-if="!psy.length && find.length >= 3">
                  <b class="purple--text">Tidak di temukan data {{ find }}</b>
                </div>
                <div v-if="selectPsy">
                  <div class="py-2">
                    <v-divider></v-divider>
                  </div>
                  <b class="purple--text">Terpilih</b>
                  <v-card class="pa-3 rounded-lg my-2">
                    <div class="d-flex align-center">
                      <div class="pp">
                        <img
                          :src="
                            selectPsy.photo_profile
                              ? `${env}/upload/photo_profile/${selectPsy.id}/${selectPsy.photo_profile}`
                              : dummy
                          "
                          class="props_img"
                          alt=""
                        />
                      </div>
                      <div class="info_">
                        <b>{{ selectPsy.nama_lengkap }}</b>
                        <p class="ma-0 color_txt">
                          {{
                            selectPsy.bidang_id == 1
                              ? "Psikologi Klinis"
                              : selectPsy.bidang_id == 2
                              ? "Psikologi Industri & Organisasi"
                              : "Psikologi Pendidikan"
                          }}
                        </p>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
            <v-overlay
              :absolute="true"
              color="white"
              opacity="1"
              :value="loading"
            >
              <v-progress-circular
                indeterminate
                class="mr-2"
                color="purple"
              ></v-progress-circular>
              <b class="purple--text">Loading...</b>
            </v-overlay>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="purple" dark @click="generateVoucher">
            Generate
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "createVoucher",
  computed: {
    ...mapState({
      env: (state) => state.API_URL,
      dummy: (state) => state.dummy,
    }),
  },
  data() {
    return {
      loading: false,
      role: "",
      time: false,
      date: false,
      ListPackage: [
        { id: 1, nama: "Paket A" },
        { id: 2, nama: "Paket B" },
      ],
      form: {
        id: "",
        kode: "",
        deskripsi: "",
        expire_date: "",
        expire_time: "23:59:00",
        kuota: 1,
        tipe: "konsultasi",
        layanan_id: "",
      },
      listBerlaku: [
        {
          txt: "Semua Psikolog",
          val: 1,
        },
        {
          txt: "Psikolog Spesifik",
          val: 2,
        },
      ],
      find: "",
      berlaku: 1,
      psy: [],
      loadPsy: false,
      selectPsy: null,
    };
  },
  mounted() {
    this.getVoucher();
  },
  methods: {
    getVoucher() {
      let id = this.$route.params.id;
      this.loading = true;
      let data = {
        page: 1,
        limit: 100,
        find: "",
        id: id,
      };
      this.$store.dispatch("counseling/listVoucher", data).then((data) => {
        let vcr = data.data.data[0];
        this.form = {
          id: id,
          kode: vcr.kode,
          deskripsi: vcr.deskripsi,
          expire_date: vcr.expire_date,
          expire_time: vcr.expire_time,
          kuota: vcr.kuota,
          tipe: "konsultasi",
          layanan_id: vcr.layanan.layanan_id,
        };
        console.log(vcr);
        if (vcr.layanan.konselor) {
          this.selectPsy = vcr.layanan.konselor;
          this.berlaku = 2;
        }
        this.loading = false;
      });
    },
    pickPsy(item) {
      this.selectPsy = item;
      this.clearSearch();
    },
    clearSearch() {
      this.find = "";
      this.findPsy("clear");
    },
    findPsy(clear) {
      if (this.find.length >= 3 || clear) {
        this.loadPsy = true;
        let data = {
          page: 1,
          limit: 100,
          find: this.find,
        };
        this.$store
          .dispatch("counseling/listVerifiedPsy", data)
          .then((data) => {
            this.psy = data.data;
            this.loadPsy = false;
          });
      }
    },
    generateVoucher() {
      this.loading = true;
      if (this.berlaku == 1) {
        this.selectPsy = null;
      }
      let body = {
        id: this.form.id,
        kode: this.form.kode,
        deskripsi: this.form.deskripsi,
        expire_date: this.form.expire_date,
        expire_time: this.form.expire_time,
        kuota: this.form.kuota,
        tipe: this.form.tipe,
        layanan_id: this.form.layanan_id,
        user_id: this.selectPsy ? this.selectPsy.id : null,
      };
      this.$store
        .dispatch("counseling/updateVcr", body)
        .then((data) => {
          this.loading = false;
          this.$router.go(-1);
          Swal.fire({
            title: data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          this.loading = false;

          console.log(err);
        });
    },
    cancelStore() {
      this.$emit("close");
      this.$emit("refetch");
    },
  },
};
</script>

<style scoped>
.toolbar {
  width: 50%;
  background: #eae8e8;
  border-radius: 10px 0 0 10px !important;
}
.list_psy {
  width: 50%;
}
.another_filter {
  background: #eae8e8;
  padding: 5px;
  width: max-content;
  margin-bottom: -15px;
}
.circle_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3b053c;
}
.litle_ {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}

.props_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.active_time {
  background: #3b053c !important;
  color: #fff !important;
}
</style>
